<template>
  <div>
    <h5 class="p-t-20 text-center bold p-b-10 auth-title">{{ $t('auth.forgot password page') }}</h5>
    <ValidationObserver ref="form_forgot_password" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off" @submit.prevent="passes(forgotPassword)">
        <HeaderNotice ref="notice"></HeaderNotice>
        <AppInput input-style="normal" name="email" type="email" rules="required" :label="$t('common.mail address')"
                  v-model="email"></AppInput>
        <p>{{ $t('auth.forgot password note') }}</p>
        <div class="row">
          <div class="col-md-6">
            <button @click="$router.pushByName(loginRoute)" class="btn btn-default" style="width: 100%" type="button">
              {{ $t('common.cancel') }}
            </button>
          </div>
          <div class="col-md-6">
            <button class="btn btn-complete m-l-10" style="width: 100%" type="submit">{{ $t('common.send') }}</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import HeaderNotice from '@components/_common/HeaderNotice';

export default {
  data() {
    let loginRoute = null;
    switch (this.$store.state.setting.authType) {
      case 'adminAuth': {
        loginRoute = this.$consts.ROUTES.ADMIN.AUTH.LOGIN
        break;
      }
      case 'csAuth': {
        loginRoute = this.$consts.ROUTES.CS.AUTH.LOGIN
        break;
      }
      case 'hotelAuth': {
        loginRoute = this.$consts.ROUTES.HOTEL.AUTH.LOGIN
        break;
      }
      case 'taxiAuth': {
        loginRoute = this.$consts.ROUTES.TAXI.AUTH.LOGIN
        break;
      }
    }
    return {
      email: null,
      loginRoute: loginRoute,
    }
  },
  components: {HeaderNotice},
  methods: {
    async forgotPassword() {
      let role = null;
      switch (this.$store.state.setting.authType) {
        case 'adminAuth': {
          role = this.$consts.ROLES.ROLE_ADMIN_NAME
          break;
        }
        case 'csAuth': {
          role = this.$consts.ROLES.ROLE_CS_NAME
          break;
        }
        case 'hotelAuth': {
          role = this.$consts.ROLES.ROLE_HOTEL_NAME
          break;
        }
        case 'taxiAuth': {
          role = this.$consts.ROLES.ROLE_TAXI_NAME
          break;
        }
      }
      const res = await this.$request.post(this.$consts.API.AUTH.FORGOT_PASSWORD, {
        email: this.email,
        role: role
      })
      if (!res.hasErrors()) {
        this.$refs.notice.setSuccess(res.data.msg, 3000);
      } else {
        this.$refs.notice.setError(res.data.msg, 3000);
      }
    },
  }
}
</script>
